import { Fragment, useState, useEffect } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ProjectCard from "./Project-card";
import  Navbar  from './Navbar';
import Footer from "./Footer";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon
} from "@heroicons/react/20/solid";

const sortOptions = [
  { name: "Finished date (descending)", value: "finished-date-descending",  href: "#", current: true },
  { name: "Finished date (ascending)", value: "finished-date-ascending", href: "#", current: false },
  { name: "Last update date (descending)", value: "last-update-date-descending", href: "#", current: false },
  { name: "Last update date (ascending)",  value: "last-update-date-ascending", href: "#", current: false },
];

const data = [
  {
    "id": 10,
    "title": "Monpanier.com",
    "image": "./img/panier.png",
    "description": "Food health app we did using HTML/CSS with Alpine, Tailwind, Django.",
    "update": "06-04-2023",
    "finished": "06-04-2023",
    "links": {
      "github": "https://github.com/thomas-mrx/MonPanier",
      "test": "https://monpanier.datavores.fr/",
      "document": ""
    },
    "filters": {
      "language": ["python", "javascript"],
      "type": ["web app", "rest-api-app", "ci/cd-pipeline"],
      "stack": ["django", "alpine"],
      "role": ["fullstack-developer", "frontend-developer","backend-developer", "devops-developer"],
      "tags": ["testable-wo-download", "school-project",  "epita-project"]
    }
  },
  {
    "id": 4,
    "title": "Pokedex API",
    "image": "./img/pokedex.png",
    "description": "Node.js api from scratch with docker implementation and a swagger for documentation.",
    "update": "15-01-2023",
    "finished": "02-01-2023",
    "links": {
      "github": "https://github.com/VictorTruong18/pokedex-api",
      "test": "",
      "document": ""
    },
    "filters": {
      "language": ["javascript"],
      "type": ["rest-api-app"],
      "stack": ["nodejs", "docker", "mysql"],
      "role": ["backend-developer", "devops-developer"],
      "tags": ["testable-w-download", "school-project", "epita-project"]
    }
  },
  {
    "id": 1,
    "title": "Outstagram",
    "image": "./img/outstagram.png",
    "description": "Angular typescript application to publish photos using a serverless architecture. I haved used many AWS cloud services to build this project. Test credentials ( username : test, password : iamtest).",
    "update": "31-05-2022",
    "finished": "31-05-2022",
    "links": {
      "github": "",
      "test": "https://outstagram.eu/",
      "document": "https://docs.google.com/presentation/d/1y1gyzB-b41z9Tc5wJZ4-37-oE33SLPiA/edit?pli=1#slide=id.p1"
    },
    "filters": {
      "language": ["typescript", "javascript"],
      "type": ["web app"],
      "stack": ["angular", "nodejs", "aws"],
      "role": [
        "fullstack-developer",
        "frontend-developer",
        "backend-developer",
        "solution-architect",
        "cloud-engineer"
      ],
      "tags": ["testable-wo-download", "school-project", "epita-project"]
    }
  },
  {
    "id": 2,
    "title": "Lamajet",
    "image": "./img/lamajet.png",
    "description": "Fun game made with javascript, and the pixijs library. The Project was finished in only 5 days during a Game Jam organized by Voodoo.",
    "update": "27-03-2022",
    "finished": "27-03-2022",
    "links": {
      "github": "https://github.com/VictorTruong18/llama-jet-game-js",
      "test": "https://victortruong.fr/lamajet",
      "document": ""
    },
    "filters": {
      "language": ["javascript"],
      "type": ["web app", "game"],
      "stack": [],
      "role": [
        "fullstack-developer",
        "frontend-developer",
        "backend-developer"
      ],
      "tags": ["testable-wo-download", "school-project", "epita-project"]
    }
  },


  {
    "id": 3,
    "title": "Wolf and Sheep",
    "image": "./img/wolf.png",
    "description": "C++ game made with sdl2 library with code scalability, open-close principle, memory management.",
    "update": "17-01-2023",
    "finished": "14-02-2022",
    "links": {
      "github": "https://github.com/VictorTruong18/shepherd-game-cpp",
      "test": "",
      "document": ""
    },
    "filters": {
      "language": ["cpp"],
      "type": ["game"],
      "stack": [],
      "role": [],
      "tags": ["testable-w-download", "school-project", "epita-project"]
    }
  },
  {
    "id": 6,
    "title": "Videogame Search Engine",
    "image": "./img/videogame.png",
    "description": "Spring application indexing a JSON file of video games into an elastic search through a rabbitmq. This project uses code coverage checks and a github action pipeline.",
    "update": "05-04-2023",
    "finished": "09-02-2022",
    "links": {
      "github": "https://github.com/VictorTruong18/video-game-search-engine",
      "test": "",
      "document": ""
    },
    "filters": {
      "language": ["java"],
      "type": ["web-app", "ci/cd-pipeline"],
      "stack": ["spring", "rabbitmq", "elasticsearch", "github-actions"],
      "role": ["fullstack-developer", "devops-developer", "backend-developer"],
      "tags": ["testable-w-download", "school-project", "epita-project"]
    }
  },
  {
    "id": 5,
    "title": "Image Search Engine",
    "image": "./img/flickr.png",
    "description": "Typescript image search engine app powered by flickr api.",
    "update": "17-12-2021",
    "finished": "17-12-2021",
    "links": {
      "github": "https://github.com/VictorTruong18/flickr-angular",
      "test": "",
      "document": ""
    },
    "filters": {
      "language": ["javascript", "typescript"],
      "type": ["web-app"],
      "stack": ["angular"],
      "role": ["fullstack-developer", "frontend-developer"],
      "tags": ["testable-w-download", "school-project", "epita-project"]
    }
  },
  {
    "id": 8,
    "title": "Drunk driver game",
    "image": "./img/drunk.png",
    "description": "Game made with Unity game engine.",
    "update": "02-12-2021",
    "finished": "02-12-2021",
    "links": {
      "github": "https://gitlab.com/victor.truong18/drunk-driver-simulation-game",
      "test": "",
      "document": ""
    },
    "filters": {
      "language": ["c#"],
      "type": ["game"],
      "stack": ["unity"],
      "role": [],
      "tags": ["testable-w-download", "school-project", "epita-project"]
    }
  },
  {
    "id": 9,
    "title": "Store Sales Analysis",
    "image": "./img/store.png",
    "description": "We were provided during this project several 'csv' files which contained data from a store. From those data we had to extract informations in order to answer business questions.",
    "update": "06-02-2020",
    "finished": "06-02-2020",
    "links": {
      "github": "https://github.com/VictorTruong18/store-sales-analysis-python?tab=readme-ov-file",
      "test": "",
      "document": ""
    },
    "filters": {
      "language": ["python"],
      "type": ["notebook"],
      "stack": [],
      "role": ["data-analyst"],
      "tags": ["testable-wo-download", "school-project", "descartes-project"]
    }
  },
  {
    "id": 7,
    "title": "Taquin atomique",
    "image": "./img/taquin.png",
    "description": "Game made with Visual Basic.",
    "update": "02-02-2020",
    "finished": "02-02-2020",
    "links": {
      "github": "https://github.com/VictorTruong18/taquin-atomique-vb.net",
      "test": "",
      "document": ""
    },
    "filters": {
      "language": ["vba"],
      "type": ["game"],
      "stack": [],
      "role": [],
      "tags": ["testable-w-download", "school-project", "descartes-project"]
    }
  },
 
]


// const subCategories = [
//   { name: 'Applications', href: '#' },
//   { name: 'Articles', href: '#' },
//   { name: 'Open-source contributions', href: '#' },
// ]
const filters = [
  {
    id: "language",
    name: "Language",
    options: [
      { value: "javascript", label: "javascript", checked: false, count: 0 },
      { value: "typescript", label: "typescript", checked: false, count: 0  },
      { value: "cpp", label: "cpp", checked: false, count: 0  },
      { value: "python", label: "python", checked: false, count: 0  },
      { value: "java", label: "java", checked: false, count: 0  },
      { value: "vba", label: "vba", checked: false, count: 0  },
      { value: "c#", label: "c#", checked: false, count: 0  },
    ],
  },
  {
    id: "stack",
    name: "Stack",
    options: [
      { value: "angular", label: "angular", checked: false, count: 0  },
      { value: "nodejs", label: "nodejs", checked: false, count: 0  },
      { value: "aws", label: "aws", checked: false, count: 0  },
      { value: "docker", label: "docker", checked: false, count: 0  },
      { value: "mysql", label: "mysql", checked: false, count: 0  },
      { value: "spring", label: "spring", checked: false, count: 0  },
      { value: "rabbitmq", label: "rabbitmq", checked: false },
      { value: "elasticsearch", label: "elasticsearch", checked: false, count: 0  },
      { value: "unity", label: "unity", checked: false, count: 0  },
      { value: "alpine", label: "alpine", checked: false, count: 0  },
      { value: "django", label: "django", checked: false, count: 0  }
    ],
  },

  {
    id: "role",
    name: "Role",
    options: [
      {
        value: "fullstack-developer",
        label: "fullstack developer",
        checked: false,
        count: 0 
      },
      {
        value: "frontend-developer",
        label: "frontend developer",
        checked: false,
        count: 0 
      },
      {
        value: "backend-developer",
        label: "backend developer",
        checked: false,
        count: 0 
      },
      { value: "devops-developer", label: "devops developer", checked: false, count: 0  },
      { value: "mobile-developer", label: "mobile developer", checked: false, count: 0  },
      { value: "data-analyst", label: "data analyst", checked: false, count: 0  },
      { value: "data-science", label: "data science", checked: false, count: 0  },
      { value: "data-engineer", label: "data engineer", checked: false, count: 0  },
      {
        value: "solution-architect",
        label: "solution architect",
        checked: false,
        count: 0 
      },
      { value: "cloud-engineer", label: "cloud engineer", checked: false, count: 0  },
    ],
  },
  {
    id: "type",
    name: "Type",
    options: [
      { value: "web-app", label: "web app", checked: false, count: 0 },
      { value: "ci/cd-pipeline", label: "CI/CD pipeline", checked: false, count: 0 },
      { value: "mobile-app", label: "mobile app", checked: false, count: 0  },
      { value: "rest-api-app", label: "rest api app", checked: false, count: 0  },
      { value: "notebook", label: "notebook", checked: false, count: 0  },
      { value: "game", label: "game", checked: false, count: 0  },
    ],
  },
  {
    id: "tags",
    name: "Tags",
    options: [
      {
        value: "testable-wo-download",
        label: "testable (no download)",
        checked: false,
        count: 0 
      },
      {
        value: "testable-w-download",
        label: "testable (with download)",
        checked: false,
        count: 0 
      },
      { value: "personal-project", label: "personal project", checked: false, count: 0  },
      { value: "school-project", label: "school project", checked: false, count: 0  },
      { value: "epita-project", label: "epita", checked: false, count: 0  },
      { value: "descartes-project", label: "iut paris", checked: false, count: 0  },
    ],
  },
];

var active_Filters = new Set();

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProjectPage() {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [jsonData, setJsonData] = useState(data);
  const [filtering, setFilters] = useState(filters);
  const [activeFilters, setActiveFilters] = useState(new Set());
  const [activeFiltersArray, setActiveFiltersArray] = useState([]);
  const [sortingOptions, setSortingOptions] = useState(sortOptions);
  const [activeSort, setActiveSort] = useState(sortOptions[0].value);


  function showActiveFilters(optionValue) {
     // Create a copy of the existing set
     const updatedFilters = new Set(activeFilters);
     if (!activeFilters.has(optionValue)) {
       // Add the new filter option
       updatedFilters.add(optionValue);
     } else {
       // Remove the filter option
       updatedFilters.delete(optionValue);
     }
     // Update the state with the new set
     setActiveFilters(updatedFilters);
     active_Filters = updatedFilters;
     
     // Updated array
     const updatedFiltersArray = Array.from(updatedFilters);
     setActiveFiltersArray(updatedFiltersArray)
  }

  function filterElements(data) {
    
    return data.filter(item => {
      var current_filter = new Set(active_Filters)

      item.filters.language.forEach((language, index) => {
        current_filter.delete(language)
      });

      item.filters.stack.forEach((stack, index) => {
        current_filter.delete(stack)
      });

      item.filters.role.forEach((role, index) => {
        current_filter.delete(role)
      });

      item.filters.type.forEach((type, index) => {
        current_filter.delete(type)
      });

      item.filters.tags.forEach((tags, index) => {
        current_filter.delete(tags)
      });

      return current_filter.size === 0;
    })

  }


  function modifyTheSelection(){

    var copydata = data
    // Filter the data based on active filters
    const filteredData = filterElements(copydata);
   
    const sortedData = applyCurrentSorting(activeSort, filteredData)

    setJsonData(sortedData);
    setFilters(countFiltersAndUpdateOptions(filteredData, filters));
    // Update the state with the filtered data
 
  }

  const updateSortingOptions = (selectedValue) => {
    const updatedOptions = sortingOptions.map(option => {
      if (option.value === selectedValue) {
        return { ...option, current: true };
      } else {
        return { ...option, current: false };
      }
    });
  
    setSortingOptions(updatedOptions);
  };

  const sortByFinishedDateDescending = (projects) => {
    return projects.slice().sort((a, b) => {
      const dateA = new Date(
        a.finished.split('-').reverse().join('-')
      ).getTime();
      const dateB = new Date(
        b.finished.split('-').reverse().join('-')
      ).getTime();
      return dateB - dateA;
    });
  };

  const sortByFinishedDateAscending = (projects) => {
    return projects.slice().sort((a, b) => {
      const dateA = new Date(
        a.finished.split('-').reverse().join('-')
      ).getTime();
      const dateB = new Date(
        b.finished.split('-').reverse().join('-')
      ).getTime();
      return dateA - dateB;
    });
  };

  const sortByUpdatedDateDescending = (projects) => {
    return projects.slice().sort((a, b) => {
      const dateA = new Date(
        a.update.split('-').reverse().join('-')
      ).getTime();
      const dateB = new Date(
        b.update.split('-').reverse().join('-')
      ).getTime();
      return dateB - dateA;
    });
  };

  const sortByUpdatedDateAscending = (projects) => {
    return projects.slice().sort((a, b) => {
      const dateA = new Date(
        a.update.split('-').reverse().join('-')
      ).getTime();
      const dateB = new Date(
        b.update.split('-').reverse().join('-')
      ).getTime();
      return dateA - dateB;
    });
  };

  function applyCurrentSorting(sortValue, jsonData){
    if (sortValue === "finished-date-descending") {
      return sortByFinishedDateDescending(jsonData)
    }

    if (sortValue === "finished-date-ascending") {
      return sortByFinishedDateAscending(jsonData)
    }

    if (sortValue === "last-update-date-descending") {
      return sortByUpdatedDateDescending(jsonData)
    }

    if (sortValue === "last-update-date-ascending") {
      return sortByUpdatedDateAscending(jsonData)
    }
  }
  
  

  function handleSortChange(sortValue) {
    updateSortingOptions(sortValue)
    setActiveSort(sortValue)
    const sortedProjects = applyCurrentSorting(sortValue, jsonData)
    setJsonData(sortedProjects)
  } 

  function checkboxChecked(sectionId, optionValue) {
    
    // clone the filtering 
    var cloneFiltering = filtering.slice()
    // modify it
    
    // get the option filter
    // Find the filter object with the given id
    const filter = cloneFiltering.find(filter => filter.id === sectionId);

    const option = filter.options.find(option => option.value === optionValue);
 
    option.checked = !option.checked;

    setFilters(cloneFiltering)
  }

  function handleFilterChange(sectionId, optionValue) {

    checkboxChecked(sectionId, optionValue)
    showActiveFilters(optionValue);
    modifyTheSelection()
    
  }

  function countFiltersAndUpdateOptions(jsonData, filters) {
   // Initialize counts to 0
   const counts = {};

   // Initialize counts for each filter option to 0
   filters.forEach(filter => {
       counts[filter.id] = {};
       filter.options.forEach(option => {
           counts[filter.id][option.value] = 0;
       });
   });

   // Count occurrences
   jsonData.forEach(item => {
       filters.forEach(filter => {
           item.filters[filter.id].forEach(value => {
               counts[filter.id][value]++;
           });
       });
   });

   // Update options with counts
   filters.forEach(filter => {
       filter.options.forEach(option => {
           option.count = counts[filter.id][option.value];
       });
   });

   return filters;
  }

  useEffect(() => {
    setFilters(countFiltersAndUpdateOptions(data, filters));

  }, []);

  return (
    <>
    <Navbar />
    <div className="bg-white">
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filters
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="mt-4 border-t border-gray-200">
                    <h3 className="sr-only">Categories</h3>
                    {/* <ul role="list" className="px-2 py-3 font-medium text-gray-900">
                      {subCategories.map((category) => (
                        <li key={category.name}>
                          <a href={category.href} className="block px-2 py-3">
                            {category.name}
                          </a>
                        </li>
                      ))}
                    </ul> */}

                    {filtering.map((section) => (
                      <Disclosure
                        as="div"
                        key={section.id}
                        className="border-t border-gray-200 px-4 py-6"
                      >
                        {({ open }) => (
                          <>
                            <h3 className="-mx-2 -my-3 flow-root">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                <span className="font-medium text-gray-900">
                                  {section.name}
                                </span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <PlusIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-6">
                                {section.options.map((option, optionIdx) => (
                                  <div
                                    key={option.value}
                                    className="flex items-center"
                                  >
                                    <input
                                      id={`filter-mobile-${section.id}-${optionIdx}`}
                                      name={`${section.id}[]`}
                                      defaultValue={option.value}
                                      type="checkbox"
                                      onChange={() => handleFilterChange(section.id, option.value)} 
                                      defaultChecked={option.checked}
                                      className="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-500"
                                    />
                                    <label
                                      htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                      className="ml-3 min-w-0 flex-1 text-gray-500"
                                    >
                                      {option.label}
                                      { option.count !== 0 && <span class="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-gray-200  bg-gray-700 rounded-full"> { option.count } </span> }
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
             
        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          
          <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-24">
            
            <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              My Projects <span class="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-gray-200  bg-gray-700 rounded-full  mr-3"> { jsonData && jsonData.length} </span>
              { 
              // Active filters
              activeFiltersArray && activeFiltersArray.map((filter) => ( 
                <span
                key={1}
                className=" bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-1"
                > {filter} </span>     
              ))
              
              }  

            </h1>  
 
            <div className="flex items-center">
            
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    Sort
                    <ChevronDownIcon
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {sortingOptions.map((option) => (
                        <Menu.Item key={option.name} onClick={() => handleSortChange(option.value)}>
                          {({ active }) => (
                            <a
                              href={option.href}
                              className={classNames(
                                option.current
                                  ? "font-medium text-gray-900"
                                  : "text-gray-500",
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm",
                              )}
                            >
                              {option.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              {/* TODO : view grid*/}
              {/* <button type="button" className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7">
                <span className="sr-only">View grid</span>
                <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
              </button> */}
              <button
                type="button"
                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="sr-only">Filters</span>
                <FunnelIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="pb-24 pt-6">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            <div className="container mx-auto px-4 py-8 flex">
              <div className="hidden lg:block w-1/4 mr-8">
                {/* Filters */}
                <form className="hidden lg:block">
                  {/* <h3 className="sr-only">Categories</h3>
                <ul role="list" className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900">
                  {subCategories.map((category) => (
                    <li key={category.name}>
                      <a href={category.href}>{category.name}</a>
                    </li>
                  ))}
                </ul> */}

                  {filtering.map((section) => (
                    <Disclosure
                      as="div"
                      key={section.id}
                      className="border-b border-gray-200 py-6"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="-my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                              <span className="font-medium text-gray-900">
                                {section.name}
                              </span>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-4">
                              {section.options.map((option, optionIdx) => (
                                <div
                                  key={option.value}
                                  className="flex items-center"
                                >
                                  <input
                                    id={`filter-${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    defaultValue={option.value}
                                    type="checkbox"
                                    defaultChecked={option.checked}
                                    onChange={() => handleFilterChange(section.id, option.value)} 
                                    className="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-500"
                                  />
                                  <label
                                    htmlFor={`filter-${section.id}-${optionIdx}`}
                                    className="ml-3 text-sm text-gray-600"
                                  >
                                    {option.label}
                                  </label>
                                  { option.count !== 0 && <span class="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-gray-200  bg-gray-700 rounded-full"> { option.count } </span> }
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </form>
              </div>

              {/* Product grid */}
              <div className="w-4/4">
                <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                  {/* Project cards */}
                  {jsonData &&
                    jsonData.map((project) => (
                      <ProjectCard
                        title={project.title}
                        image={project.image}
                        description={project.description}
                        githublink={project.links.github}
                        testlink={project.links.test}
                        documentlink={project.links.document}
                        languagetags={project.filters.language}
                        typetags={project.filters.type}
                        stacktags={project.filters.stack}
                        roletags={project.filters.role}
                        tags={project.filters.tags}
                        updated={project.update}
                        finished={project.finished}
                      />
                    ))}
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
    <Footer />
    </>
  );
                    }
