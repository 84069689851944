import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';


import ProjectPage from "./Projects";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<ProjectPage />} />
        <Route exact path="/experience" element={<h1>Experience Page</h1>} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
        
  );
}

export default App;
